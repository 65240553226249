<template>
  <FocusTrap>
    <div class="card" id="mycard" >
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter User information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <form>
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-user"></i> </span>
            </div>
            <input id="txtname" class="form-control" placeholder="Full name" type="text" autocomplete="off" v-model="user.name">
          </div> <!-- form-group// -->

          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
            </div>
            <input name="" class="form-control" placeholder="Email address" type="email" autocomplete="off" v-model="user.email" required>
          </div> <!-- form-group// -->

          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-phone"></i> </span>
            </div>
            <select class="custom-select" style="max-width: 120px;">
              <option selected="">+91</option>
            </select>
            <input name="" class="form-control" placeholder="Phone number" type="tel" autocomplete="off" v-model="user.mobile">
          </div> <!-- form-group// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-building"></i> </span>
            </div>
            <select class="form-control" v-model="user.territory_id" >
              <option value="0" selected=""> Select Territory</option>
              <option v-for="area in areas" v-bind:value="area.id">
                {{ area.name }}
              </option>
            </select>
          </div> <!-- form-group end.// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
            </div>
            <input class="form-control" placeholder="Create password" type="password" autocomplete="off" v-model="user.password"  >
          </div> <!-- form-group// -->
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
            </div>
            <input class="form-control" placeholder="Repeat password" type="password" autocomplete="off" v-model="user.c_password">
          </div> <!-- form-group// -->
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block" @click="saveDocument"> Create Account  </button>
          </div> <!-- form-group// -->

        </form>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
export default {
  name: 'UserForm',
  data() {
    return {
      user: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","email":"","territory_id":1,"mobile":"","password":"","c_password":""}'),
      areas : [],
    }
  },
  component: {

  },
  props: {
    myuser: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","email":"","territory_id":1,"mobile":"","password":"","c_password":""}')
    }
  },
  beforeMount () {
    this.user = this.myuser; // save props data to itself's data and deal with tms
  },
  created () {
    this.$data.user = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","email":"","territory_id":1,"mobile":"","password":"","c_password":""}')
  },
  mounted () {
    this.loadAreas();
    $('.form-control-select2').select2()
    $('#txtname').focus();
  },
  methods: {
    closeWindow(){
      this.user = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","email":"","territory_id":1,"mobile":"","password":"","c_password":""}');
      this.$emit("user_window_closed");
    },
    loadAreas () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.areas = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/area/territories`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data) ){
            self.$data.areas = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      })

    },
    saveDocument(){

      const self = this;

      if(self.$data.user.name.toString().length < 5){
        alert("Invalid Name");
        return;
      }else if( parseInt(self.user.territory_id ) < 1 ){
        alert("Invalid Territory selected");
        return;
      }else if( parseInt(self.$data.user.mobile.length ) != 10 ){
        alert("Invalid Mobile");
        return;
      }else if(self.$data.user.id == 0){
        if(self.$data.user.password != self.$data.user.c_password) {
          alert("Password mismatch");
          return;
        }
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      const requestOptions = {
        method:  (self.$data.user.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.user)
      };

      let user_url = self.$data.user.id == 0 ? `${process.env.VUE_APP_ROOT_API}api/register` : `${process.env.VUE_APP_ROOT_API}api/user`;

      fetch(user_url,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.user = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","email":"","territory_id":1,"mobile":"","password":"","c_password":""}');
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.$emit('user_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    }
  }
}
</script>

<style scoped>

</style>
